define("ember-svg-jar/inlined/portal_message_always", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M0 0h11.725v1.636H1.655c.063 1.312.313 2.744 1.105 4.093 1.211-1.046 2.413-1.365 3.511-1.365.703 0 1.491.228 2.12.627.617.393 1.243 1.067 1.243 2.009 0 .852-.438 1.542-1.076 1.988-.622.436-1.433.648-2.287.648-1.498 0-2.431-.676-3.484-1.489-.808 1.247-1.069 2.814-1.133 4.217h10.07V14H0v-.818c0-1.683.177-4.23 1.61-6.21C.192 4.843 0 2.552 0 .819V0zm3.884 6.92C4.88 7.677 5.361 8 6.271 8c.601 0 1.062-.152 1.349-.352.27-.19.378-.41.378-.648 0-.149-.102-.384-.485-.628A2.471 2.471 0 006.27 6c-.693 0-1.498.174-2.387.92z\" fill=\"#28A745\"/>",
    "attrs": {
      "width": "12",
      "height": "14",
      "viewBox": "0 0 12 14",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});