define("ember-svg-jar/inlined/check-circle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g clip-path=\"url(#clip0_9394_80853)\" stroke=\"#28A745\" stroke-linecap=\"round\" stroke-linejoin=\"round\"><path d=\"M14.667 7.387V8a6.666 6.666 0 11-3.954-6.093\"/><path d=\"M14.667 2.667L8 9.34l-2-2\"/></g><defs><clipPath id=\"clip0_9394_80853\"><path fill=\"#fff\" d=\"M0 0h16v16H0z\"/></clipPath></defs>",
    "attrs": {
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});