define("ember-svg-jar/inlined/message_notification_email_only_when_necessary", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M15 2.001l-14 12\" stroke=\"#DC3545\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M11.327 2.304A4.5 4.5 0 003.5 5.334c0 2.216-.465 3.62-.912 4.461l1.561-1.338c.211-.84.351-1.87.351-3.123a3.5 3.5 0 016.067-2.378l.76-.652zm.817 1.276l-.809.693c.108.34.165.698.165 1.061 0 2.408.517 3.992 1.061 4.99.105.192.21.361.313.51H3.68l-1.166 1H14a.5.5 0 00.282-.912l-.006-.005a2.186 2.186 0 01-.226-.208 4.125 4.125 0 01-.611-.864c-.455-.835-.939-2.25-.939-4.51a4.5 4.5 0 00-.356-1.755zm-5.548 9.989a.5.5 0 01.683.181.833.833 0 001.442 0 .5.5 0 11.865.502 1.833 1.833 0 01-3.172 0 .5.5 0 01.182-.683z\" fill=\"#DC3545\"/>",
    "attrs": {
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});