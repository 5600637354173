define("ember-svg-jar/inlined/text_message_only_when_necessary", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M11.072 1.168A6.24 6.24 0 002.301 8.59l.844-.715a5.24 5.24 0 017.083-5.993l.844-.714zM3.91 9.848a.5.5 0 01-.041.164L2.98 12.02l2.008-.889a.5.5 0 01.47.035 5.24 5.24 0 006.506-8.134l.766-.647a6.24 6.24 0 01-7.575 9.766l-2.953 1.306a.5.5 0 01-.66-.66l.67-1.513L3.91 9.848z\" fill=\"#DC3545\"/><path d=\"M14 1L1 12\" stroke=\"#DC3545\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "width": "15",
      "height": "14",
      "viewBox": "0 0 15 14",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});