define("ember-svg-jar/inlined/email_only_when_necessary", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g clip-path=\"url(#clip0_10153_86144)\"><path d=\"M8 8L2 4\" stroke=\"#DC3545\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M12.156 2.25H3.333A2.087 2.087 0 001.25 4.334v7.145l1.5-1.27V4.334c0-.32.264-.584.583-.584h7.05l1.773-1.5zM2.89 12.712a.583.583 0 00.444.208h10.664a.587.587 0 00.583-.584V4.334a.587.587 0 00-.583-.584h-.517l1.166-.987a.996.996 0 00.246-.31 2.088 2.088 0 011.188 1.88v8.003a2.087 2.087 0 01-2.083 2.084H3.333a2.08 2.08 0 01-1.59-.74l1.146-.968z\" fill=\"#DC3545\"/><path d=\"M15 2L1 14\" stroke=\"#DC3545\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/></g><defs><clipPath id=\"clip0_10153_86144\"><path fill=\"#fff\" d=\"M0 0h16v16H0z\"/></clipPath></defs>",
    "attrs": {
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});