define("ember-svg-jar/inlined/phone_call_only_when_necessary", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g clip-path=\"url(#clip0_10153_86156)\" stroke=\"#DC3545\" stroke-linecap=\"round\" stroke-linejoin=\"round\"><path d=\"M3.46 8.54a13.193 13.193 0 01-2.046-5.753A1.333 1.333 0 012.74 1.334h2A1.333 1.333 0 016.074 2.48a8.56 8.56 0 00.466 1.874 1.333 1.333 0 01-.3 1.406l-.846.847M7.12 8.874c.677.677 1.441 1.26 2.274 1.733l.846-.847a1.333 1.333 0 011.407-.3 8.56 8.56 0 001.873.467 1.334 1.334 0 011.147 1.333v2a1.332 1.332 0 01-1.453 1.334 13.193 13.193 0 01-5.754-2.047 12.948 12.948 0 01-2.22-1.78l1.88-1.893zM15.333.667L.667 15.333\"/></g><defs><clipPath id=\"clip0_10153_86156\"><path fill=\"#fff\" d=\"M0 0h16v16H0z\"/></clipPath></defs>",
    "attrs": {
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});