define("ember-svg-jar/inlined/portal_message_only_when_necessary", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M11.554 1.636L13.464 0H2v.818c0 1.733.193 4.023 1.61 6.155-.555.765-.92 1.616-1.162 2.468l5.923-5.076a3.316 3.316 0 00-.1-.001c-1.098 0-2.3.32-3.51 1.365-.793-1.35-1.043-2.78-1.107-4.093h7.9zm-.984 3.478L9.262 6.235c.087.041.172.087.25.137.384.244.486.48.486.628 0 .239-.107.458-.378.648-.287.2-.748.352-1.349.352-.344 0-.627-.046-.9-.145l-1.35 1.158c.645.376 1.344.623 2.25.623.854 0 1.665-.212 2.287-.648.638-.446 1.076-1.136 1.076-1.988 0-.85-.51-1.483-1.064-1.886zm-6.773 5.805l-1.784 1.53c-.01.258-.013.504-.013.733V14h11.725v-1.636H3.654a12.8 12.8 0 01.143-1.445z\" fill=\"#DC3545\"/><path d=\"M15 1L1 13\" stroke=\"#DC3545\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "width": "16",
      "height": "14",
      "viewBox": "0 0 16 14",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});