define("ember-url-hash-polyfill/index", ["exports", "@ember/application", "@ember/debug", "@ember/destroyable"], function (_exports, _application, _debug, _destroyable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.scrollToHash = scrollToHash;
  _exports.withHashSupport = withHashSupport;

  function withHashSupport(AppRouter) {
    return class RouterWithHashSupport extends AppRouter {
      constructor() {
        super(...arguments);
        setupHashSupport(this);
      }

    };
  }

  function scrollToHash(hash) {
    let selector = `[name="${hash}"]`;
    let element = document.getElementById(hash) || document.querySelector(selector);

    if (!element) {
      (false && (0, _debug.warn)(`Tried to scroll to element with id or name "${hash}", but it was not found`, {
        id: 'no-hash-target'
      }));
      return;
    }
    /**
     * NOTE: the ember router does not support hashes in the URL
     *       https://github.com/emberjs/rfcs/issues/709
     *
     *       this means that when testing hash changes in the URL,
     *       we have to assert against the window.location, rather than
     *       the self-container currentURL helper
     *
     * NOTE: other ways of changing the URL, but without the smoothness:
     *   - window[.top].location.replace
     */


    element.scrollIntoView({
      behavior: 'smooth'
    });

    if (hash !== window.location.hash) {
      let withoutHash = location.href.split('#')[0];
      let nextUrl = `${withoutHash}#${hash}`; // most browsers ignore the title param of pushState

      let titleWithoutHash = document.title.split(' | #')[0];
      let nextTitle = `${titleWithoutHash} | #${hash}`;
      history.pushState({}, nextTitle, nextUrl);
      document.title = nextTitle;
    }
  }

  async function setupHashSupport(router) {
    let initialURL;
    let owner = (0, _application.getOwner)(router);
    await new Promise(resolve => {
      let interval = setInterval(() => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let {
          currentURL
        } = router;
        /* Private API */

        if (currentURL) {
          clearInterval(interval);
          initialURL = currentURL;
          resolve(null);
        }
      }, 100);
    });

    if ((0, _destroyable.isDestroyed)(owner) || (0, _destroyable.isDestroying)(owner)) {
      return;
    }
    /**
     * This handles the initial Page Load, which is not imperceptible through
     * route{Did,Will}Change
     *
     */


    requestAnimationFrame(() => {
      eventuallyTryScrollingTo(owner, initialURL);
    });
    let routerService = owner.lookup('service:router');

    function handleHashIntent(transition) {
      let {
        url
      } = transition.intent || {};

      if (!url) {
        return;
      }

      eventuallyTryScrollingTo(owner, url);
    }

    routerService.on('routeDidChange', handleHashIntent);
    (0, _destroyable.registerDestructor)(router, () => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      routerService
      /* type def missing "off" */
      .off('routeDidChange', handleHashIntent);
    });
  }

  const CACHE = new WeakMap();

  function eventuallyTryScrollingTo(owner, url) {
    // Prevent quick / rapid transitions from continuing to observer beyond their URL-scope
    CACHE.get(owner)?.disconnect();
    if (!url) return;
    let [, hash] = url.split('#');
    if (!hash) return;

    if ((0, _destroyable.isDestroyed)(owner) || (0, _destroyable.isDestroying)(owner)) {
      return;
    }

    scrollToHash(hash);
  }
});