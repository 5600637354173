define("ember-svg-jar/inlined/phone_call_always", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M15.392 11.28v2a1.334 1.334 0 01-1.454 1.334 13.193 13.193 0 01-5.753-2.047 13 13 0 01-4-4 13.193 13.193 0 01-2.047-5.78 1.333 1.333 0 011.327-1.453h2A1.333 1.333 0 016.798 2.48a8.56 8.56 0 00.467 1.874 1.333 1.333 0 01-.3 1.406l-.847.847a10.667 10.667 0 004 4l.847-.847a1.333 1.333 0 011.407-.3 8.558 8.558 0 001.873.467 1.333 1.333 0 011.147 1.353z\" stroke=\"#28A745\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "width": "17",
      "height": "16",
      "viewBox": "0 0 17 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});