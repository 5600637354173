define("ember-svg-jar/inlined/text_message_sent", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M14.725 7.667a5.587 5.587 0 01-.6 2.533 5.666 5.666 0 01-5.067 3.133 5.588 5.588 0 01-2.533-.6L2.725 14l1.266-3.8a5.586 5.586 0 01-.6-2.533A5.667 5.667 0 016.525 2.6 5.587 5.587 0 019.058 2h.333a5.654 5.654 0 015.334 5.333v.334z\" stroke=\"#28A745\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "width": "17",
      "height": "16",
      "viewBox": "0 0 17 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});