define("ember-svg-jar/inlined/loader", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M20 3.334v6.667M20 30v6.667M8.217 8.217l4.716 4.716M27.066 27.066l4.717 4.717M3.334 20h6.667M30 20h6.667M8.217 31.783l4.716-4.717M27.066 12.934l4.717-4.717\" stroke=\"#374151\" stroke-width=\"2.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "width": "40",
      "height": "40",
      "viewBox": "0 0 40 40",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});