define("ember-svg-jar/inlined/reply", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M430.84 139.15c-5.75 1.92-412.79 302.79-416.62 307.96-5.75 7.67-5.56 25.1.19 33.15 5.75 8.05 411.06 293.97 419.49 295.89 13.41 3.07 27.21-3.45 33.54-15.52 3.45-6.9 3.64-11.88 3.64-77.61v-70.33l25.49 1.15c66.12 3.26 132.23 16.67 192.98 39.09 45.04 16.48 103.29 49.25 140.85 79.15 36.22 28.94 75.12 71.48 96.59 105.4 13.42 21.27 16.86 23.96 32.77 23.96 14.95 0 20.89-3.64 27.02-15.91 4.79-9.58 4.41-16.86-2.3-56.92-22.42-134.15-82.4-260.24-161.74-339.2-84.51-84.32-202.37-136.25-334.79-147.56L473 300.51v-68.8c0-75.31-.19-76.46-11.31-86.81-4.98-4.79-7.86-5.75-16.86-6.13-6.13-.39-12.46-.2-13.99.38zm-19.17 146.22c0 54.04 0 55.38 4.41 61.52 8.05 12.26 13.8 13.99 49.06 15.52 93.71 4.22 180.14 31.43 251.24 78.95 24.15 16.1 36.41 26.06 59.98 49.25 37.56 36.41 63.62 73.59 89.69 127.44 13.41 27.79 31.81 73.4 35.45 88.15 1.34 5.37 1.15 5.56-2.49 2.88-2.11-1.53-12.46-10.16-22.8-18.78-34.5-29.13-84.13-59.98-128.4-79.72-83.56-37.18-169.22-55.77-267.15-57.88-46.38-.96-46.95-.96-54.62 3.45-4.98 2.88-9.2 7.47-11.88 13.03-4.22 8.24-4.41 10.35-4.41 62.67 0 29.89-.38 54.23-.77 54.23-1.34 0-308.34-216.74-312.18-220.38-3.45-3.07 3.26-8.24 154.27-119.39 86.81-63.82 158.29-116.13 159.25-116.32.78 0 1.35 24.91 1.35 55.38z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 1000 1000"
    }
  };
  _exports.default = _default;
});